import { registerGAEvent } from "../../../analytics/React-ga";
import dayjs from "dayjs";
import { getServer } from "../../../utils/Environment";
import { statistics } from "../../../statistics/statistics";
import { DOCUMENTS_TITLES } from "../../../components/documents/_CONSTANTS/constants";

const server = getServer();

const urlServer = server + "/server/server.php";

export async function exportRevisionToTxt(document, user) {
  const docBarcode = await generateDocBarcode();
  const dateForSave = getFullDateForSave();
  const docProducts = document.products.map((product) => {
    return {
      realCount: product.stockQty,
      comment: document.comment,
      date: dayjs(document.date).format("DD.MM.YYYY-HH-mm"),
      guid: product.guid,
      count: product.qty,
      price: product.price,
      docBarcode,
    };
  });

  const result = await fetch(urlServer, {
    method: "POST",
    body: JSON.stringify({
      login: user.username,
      date: dateForSave,
      res: JSON.stringify(docProducts),
      docNum: docBarcode,
      action: "saveRevisionsToCabinet",
    }),
  });

  try {
    const res = await result.json();
    registerGAEvent({
      category: user.company,
      action: user.username + " - " + DOCUMENTS_TITLES.REVISION,
      label: "Експорт",
    });
    statistics(user, DOCUMENTS_TITLES.REVISION);
    return res;
  } catch (error) {
    registerGAEvent({
      category: user.company,
      action: user.username + " - " + DOCUMENTS_TITLES.REVISION,
      label: "Помилка експорту",
    });
    return { status: "failed", message: error };
  }
}

export async function exportPrices(document, user) {
  const docBarcode = await generateDocBarcode();
  const date = getDateWithYearForSave();
  const dateForSave = getFullDateForSave();
  const products = getDocProducts(
    document.products,
    docBarcode,
    date,
    dateForSave
  );

  const result = await fetch(urlServer, {
    method: "POST",
    body: JSON.stringify({
      login: user.username,
      date: dateForSave,
      res: JSON.stringify(products),
      docNum: docBarcode,
      action: "savePricesToCabinet",
    }),
  });

  try {
    const res = await result.json();
    registerGAEvent({
      category: user.company,
      action: user.username + " - " + DOCUMENTS_TITLES.CHECK_PRICE,
      label: "Експорт",
    });
    statistics(user, DOCUMENTS_TITLES.CHECK_PRICE);
    return res;
  } catch (error) {
    registerGAEvent({
      category: user.company,
      action: user.username + " - " + DOCUMENTS_TITLES.CHECK_PRICE,
      label: "Помилка експорту",
    });
    return { status: "failed", message: error };
  }
}

export async function exportInvoice(document, user) {
  const docBarcode = await generateDocBarcode();
  // const date = getDateWithYearForSave();
  const docData = {
    comment: document.comment,
    date: dayjs().format("DD-MM-YYYY"),
    docBarcode,
    kontragent: document.supplier,
    isReturn: false,
    parentDocBarcode: document.sourceId,
  };

  const dateForSave = getFullDateForSave();
  const products = getInvoiceDocProducts(document.products);

  const result = await fetch(urlServer, {
    method: "POST",
    body: JSON.stringify({
      login: user.username,
      date: dateForSave,
      res: JSON.stringify([...products, docData]),
      docNum: docBarcode,
      action: "saveInvoiceToCabinet",
    }),
  });

  try {
    const res = await result.json();
    registerGAEvent({
      category: user.company,
      action: user.username + " - " + DOCUMENTS_TITLES.INVOICE,
      label: "Експорт",
    });
    statistics(user, DOCUMENTS_TITLES.INVOICE);
    return res;
  } catch (error) {
    registerGAEvent({
      category: user.company,
      action: user.username + " - " + DOCUMENTS_TITLES.INVOICE,
      label: "Помилка експорту",
    });
    return { status: "failed", message: error };
  }
}

export async function exportOrder(document, user) {
  const docBarcode = await generateDocBarcode();
  const docData = {
    comment: document.comment,
    date: dayjs().format("DD-MM-YYYY"),
    docBarcode,
    kontragent: document.supplier,
    isReturn: false,
    parentDocBarcode: document.sourceId,
    docNum: docBarcode,
  };

  const dateForSave = getFullDateForSave();

  const products = getOrderDocProducts(document.products, docBarcode);

  const result = await fetch(urlServer, {
    method: "POST",
    body: JSON.stringify({
      login: user.username,
      date: dateForSave,
      res: JSON.stringify([...products, docData]),
      docNum: docBarcode,
      action: "saveOrderToCabinet",
    }),
  });

  try {
    const res = await result.json();
    registerGAEvent({
      category: user.company,
      action: user.username + " - " + DOCUMENTS_TITLES.ORDER,
      label: "Експорт",
    });
    statistics(user, DOCUMENTS_TITLES.ORDER);
    return res;
  } catch (error) {
    registerGAEvent({
      category: user.company,
      action: user.username + " - " + DOCUMENTS_TITLES.ORDER,
      label: "Помилка експорту",
    });
    return { status: "failed", message: error };
  }
}

function getDocProducts(products, docBarcode, date) {
  return products.map((product) => {
    const prod = {
      ...product,
      docBarcode,
      comment: "",
      date,
      labelCount: product.qty,
    };
    delete prod.qty;
    return prod;
  });
}

function getOrderDocProducts(products, docBarcode) {
  return products.map((product) => {
    const prod = {
      ...product,
      docBarcode,
      count: product.qty,
      realCount: 0,
    };
    delete prod.qty;

    return prod;
  });
}

function getInvoiceDocProducts(products) {
  return products.map((product) => {
    const prod = {
      ...product,
      count: product.qty,
      realCount: product?.qtyFromSource ? product?.qtyFromSource : 0,
    };
    delete prod.qty;
    delete prod.qtyFromSource;
    return prod;
  });
}

async function generateDocBarcode() {
  let prefix = 24;
  var d2 = new Date().getTime();
  d2 = d2.toString();
  let unique_string = d2.substr(d2.length - 10);
  let new_doc_barcode = "" + prefix + "0" + unique_string;
  return new_doc_barcode;
}

function getDateWithYearForSave() {
  var dt = new Date();
  var month = dt.getUTCMonth() + 1; //months from 1-12
  var day = dt.getUTCDate();
  var year = dt.getUTCFullYear();
  var hours = dt.getHours();
  var minutes = dt.getMinutes();
  var seconds = dt.getSeconds();

  // var time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
  if (day < 10) {
    day = "0" + day;
  }
  if (month < 10) {
    month = "0" + month;
  }
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  var time = hours + ":" + minutes;
  var date = day + "." + month + "." + year;
  return date;
}

function getFullDateForSave() {
  var dt = new Date();
  var month = dt.getUTCMonth() + 1; //months from 1-12
  var day = dt.getUTCDate();
  var year = dt.getUTCFullYear();
  var hours = dt.getHours();
  var minutes = dt.getMinutes();
  var seconds = dt.getSeconds();

  // var time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
  if (day < 10) {
    day = "0" + day;
  }
  if (month < 10) {
    month = "0" + month;
  }
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  var time = hours + "-" + minutes;
  var date = day + "-" + month + "-" + year;
  return date + "-" + time;
}

export const exportSales = async (document, user) => {
  const docData = {
    comment: document.comment,
    sum: document.sum,
    isReturn: document.isReturn,
    docNum: document.docBarcode,
    docBarcode: document.docBarcode,
  };

  const dateForSave = dayjs().format("DD-MM-YYYY");

  const products = getSalesDocProducts(document.products, docData);

  const result = await fetch(urlServer, {
    method: "POST",
    body: JSON.stringify({
      login: user.username,
      date: dateForSave,
      res: JSON.stringify([...products]),
      docNum: docData.docNum,
      action: "saveRealizationsToCabinet",
    }),
  });

  try {
    const res = await result.json();
    registerGAEvent({
      category: user.company,
      action: user.username + " - " + DOCUMENTS_TITLES.SALE,
      label: "Експорт",
    });
    statistics(user, DOCUMENTS_TITLES.SALE);
    return res;
  } catch (error) {
    registerGAEvent({
      category: user.company,
      action: user.username + " - " + DOCUMENTS_TITLES.SALE,
      label: "Помилка експорту",
    });
    return { status: "failed", message: error };
  }
};

function getSalesDocProducts(products, docData) {
  return products.map((product) => {
    const prod = {
      docBarcode: docData.docBarcode,
      date: dayjs().format("DD.MM HH:mm"),
      sum: docData.sum,
      comment: docData.comment,
      isReturn: docData.isReturn,
      guid: product.guid,
      barcode: "",
      count: product.qty,
      price: product.price,
    };
    delete prod.qty;

    return prod;
  });
}

export const exportDocumentToTxt = async (docForExport, docTypeData, user) => {
  switch (docTypeData.value) {
    case "sales":
      return await exportSales(docForExport, user);
    case "check-price":
      return await exportPrices(docForExport, user);
    case "invoices":
      return await exportInvoice(docForExport, user);
    case "orders":
      return await exportOrder(docForExport, user);
    case "revisions":
      return await exportRevisionToTxt(docForExport, user);
    default:
      return {
        status: "failed",
        message:
          "Цей тип документу доступний тільки в xml-експорті. Зверніться в підтримку!",
      };
      break;
  }
};
