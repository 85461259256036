import { message } from "antd";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Main from "../../components/documents/returns/create/Main";
import { getRecord } from "../../db/dexie-db/functions";
import {
  getDocumentProductsWithoutDeleted,
  getRecordsForTable,
} from "../../db/documents-funtions";
import { TABLES } from "../../db/constants/tables";
import { DOCUMENT_OPEN_ACTIONS } from "../../components/documents/_CONSTANTS/constants";
import BackButton from "../../components/UI/BackButton";
import { getReturnsColumns } from "../../components/documents/manage-documents/table-components/documents-columns";

const DOCUMENT_ACTION = DOCUMENT_OPEN_ACTIONS.EDIT;
const CURRENT_DOCUMENT_TABLE = TABLES.CURRENT_RETURN;

export default function EditReturn() {
  const params = useParams();
  const id = params.id;
  const [document, setDocument] = useState();
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const doc = await getRecord(TABLES.RETURNS, Number(id));
    if (!doc) {
      message.error("Помилка отримання даних документа");
    }
    const products = doc.products;
    setDocument(doc);
    setProducts(getRecordsForTable(products));
    setLoading(false);
  };

  const returnColumns = getReturnsColumns();

  const removeProductFromDocument = async (product) => {
    const arrayWithoutDeletedProduct = await getDocumentProductsWithoutDeleted(
      products,
      product,
      CURRENT_DOCUMENT_TABLE,
      DOCUMENT_ACTION
    );
    setProducts(arrayWithoutDeletedProduct);
  };

  // const setProductQty = async (record, enteredQty) => {
  //   return setProductQuantity(
  //     products,
  //     record,
  //     enteredQty,
  //     CURRENT_DOCUMENT_TABLE,
  //     DOCUMENT_ACTION
  //   );
  // };

  // const handleChangeQty = async (record, value) => {
  //   handleChangeProductQty(
  //     record,
  //     value,
  //     removeProductFromDocument,
  //     setProductQty,
  //     setProducts
  //   );
  // };

  const afterChangeProductQty = (newProduct) => {
    setProducts((prevProducts) => {
      const productToChange = prevProducts.find(
        (product) => product.guid === newProduct.guid
      );

      if (productToChange) productToChange.qty = newProduct.qty;
      return prevProducts;
    });
  };

  return (
    <>
      <Main
        products={products}
        setProducts={setProducts}
        loading={loading}
        action={DOCUMENT_OPEN_ACTIONS.EDIT}
        document={document}
        afterChangeProductQty={afterChangeProductQty}
        removeProductFromDocument={removeProductFromDocument}
        columns={returnColumns}
      ></Main>
      <BackButton></BackButton>
    </>
  );
}
