import { message } from "antd";
import { getServer } from "../../utils/Environment";

const server = getServer();

const checkUpdateUrl = server + "/server/server.php";

export async function isUpdateNeeded(latestUpdateDate, exchangeType, username) {
  try {
    const result = await fetch(checkUpdateUrl, {
      method: "POST",
      body: JSON.stringify({
        type: exchangeType,
        login: username,
        action: "getLatestUpdateDateLocal",
      }),
    });
    const res = await result.json();
    if (!res) {
      message.error(
        "Помилка перевірки необхідності оновлення, перевірте вигрузку!"
      );
      return false;
    }
    return new Date(res) > new Date(latestUpdateDate) ? true : false;
  } catch (error) {
    message.error("Помилка перевірки необхідності оновлення");
    // console.log(error);
    // throw error;
    // return false;
  }
}
