import { message } from "antd";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Main from "../../components/documents/documents-verification/view/Main";
import { getRecord } from "../../db/dexie-db/functions";
import { getRecordsForTable } from "../../db/documents-funtions";
import BackButton from "../../components/UI/BackButton";
import BottomPageTitle from "../../components/header/BottomPageTitle";
import { useAuth } from "../../hook/useAuth";
import { getUserSetting } from "../../api/settings/settings";
import { TABLES } from "../../db/constants/tables";
import { DOCUMENT_OPEN_ACTIONS } from "../../components/documents/_CONSTANTS/constants";

export default function ViewVerification() {
  const { user } = useAuth();

  const digitalKeyboard = getUserSetting("digitalKeyboard", user.settings);
  const sounds = getUserSetting("sound", user.settings);
  const colorDifferents = getUserSetting("color_differents", user.settings);
  const additionalColorDifferents = getUserSetting(
    "additionalHighlightDifferents",
    user.settings
  );
  const revisionSettings = {
    digitalKeyboard,
    sounds,
    colorDifferents,
    additionalColorDifferents,
  };

  const params = useParams();
  const id = params.id;
  const [document, setDocument] = useState();
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [settings, setSettings] = useState(revisionSettings);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const doc = await getRecord(TABLES.VERIFICATION, Number(id));
    if (!doc) {
      message.error("Помилка отримання даних документа");
    }
    const products = doc.products;
    setDocument(doc);
    setProducts(getRecordsForTable(products));
    setLoading(false);
  };

  return (
    <>
      <Main
        products={products}
        setProducts={setProducts}
        loading={loading}
        action={DOCUMENT_OPEN_ACTIONS.VIEW}
        document={document}
        settings={settings}
      ></Main>
      <BackButton></BackButton>
      <BottomPageTitle title={"Перегляд перевірки"}></BottomPageTitle>
    </>
  );
}
