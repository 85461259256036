import { Col, Input, Row } from "antd";
import React from "react";

const InfoTab = function InfoTab(props) {
  const onChangeComment = (event) => {
    props.setComment(event.target.value);
  };

  return (
    <>
      <Row style={{ padding: "20px" }}>
        <Col offset={2} span={20}>
          <Input
            style={{ width: "100%" }}
            size={"large"}
            placeholder="Коментар"
            value={props?.comment}
            disabled={props?.disabledFields}
            onChange={onChangeComment}
          ></Input>
        </Col>
      </Row>
    </>
  );
};
export default InfoTab;
