import { SearchOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import DefaulModalBodyAddon from "./DefaulModalBodyAddon";

export default function DefaultModalBody({
  inputDataRef,
  settings,
  enterHandler,
  onFocusDataInput,
  onBlurDataInput,
  decrementQty,
  inputQtyRef,
  onFocusQtyInput,
  onBlurQtyInput,
  updateNewQtyDisplayData,
  currentProduct,
  incrementQty,
  BodyAddon,
  processSearchProduct,
}) {
  return (
    <>
      {currentProduct ? (
        <Row>
          <Col style={{ padding: "10px 0px" }} span={14}>
            {BodyAddon ? (
              <BodyAddon></BodyAddon>
            ) : (
              <DefaulModalBodyAddon
                askPrice={settings?.askPrice}
              ></DefaulModalBodyAddon>
            )}
          </Col>
          <Col
            id="enteredQtyData"
            style={{
              textAlign: "right",
              padding: "10px 0px",
              fontSize: "16px",
            }}
            span={10}
          ></Col>
        </Row>
      ) : (
        <br></br>
      )}
      <Row>
        <Col span={13}>
          <form onSubmit={() => false}>
            <InputGroup className="mb-3">
              <Form.Control
                id="inputData"
                className="inputData"
                placeholder="Введіть дані"
                ref={inputDataRef}
                type={settings?.digitalKeyboard ? "tel" : "text"}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    const enteredData = e.target.value;
                    e.target.value = "";
                    processSearchProduct(enteredData);
                  }
                }}
                autoFocus={true}
                onFocus={onFocusDataInput}
                onBlur={onBlurDataInput}
              />
              <InputGroup.Text
                id="search-icon"
                onClick={() => {
                  const enteredData = inputDataRef.current.value;
                  enterHandler(enteredData);
                  inputDataRef.current.value = "";
                }}
              >
                <SearchOutlined></SearchOutlined>
              </InputGroup.Text>
            </InputGroup>
          </form>
        </Col>
        <Col span={1}></Col>
        <Col span={10}>
          <InputGroup className="mb-3">
            <InputGroup.Text onClick={decrementQty}>-</InputGroup.Text>
            <Form.Control
              id={"inputData"}
              key={"inputData"}
              className="inputData"
              type="tel"
              placeholder="К-ть"
              ref={inputQtyRef}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  e.preventDefault();
                  const enteredData = e.target.value;
                  e.target.value = "";
                  const formattedEnteredData = enteredData.replace(/,/g, ".");
                  enterHandler(formattedEnteredData);
                }
              }}
              autoFocus={false}
              onFocus={onFocusQtyInput}
              onBlur={onBlurQtyInput}
              onInput={(e) => {
                const value = e.target.value;
                const formattedValue = value.replace(/,/g, ".");
                let enteredQty = formattedValue;
                if (currentProduct)
                  if (!currentProduct.isWeight)
                    enteredQty = parseInt(formattedValue);

                updateNewQtyDisplayData(
                  enteredQty
                    ? +enteredQty
                    : currentProduct?.isWeight
                    ? 0.01
                    : 1,
                  currentProduct
                );
              }}
            />
            <InputGroup.Text onClick={incrementQty}>+</InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>
    </>
  );
}
