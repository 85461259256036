import React from "react";
import { NumberKeyboard } from "antd-mobile";

export default function VirtualKeyboard(props) {
  const onInputVirtualKeyboard = (value) => {
    const field = props.focusedInput;
    const previousFieldValue = field.current.value || "";
    const newValue = previousFieldValue + value;
    field.current.value = newValue;
    props.updateNewQtyDisplayData(+newValue, props?.currentProduct);
  };

  const onDeleteVirtualKeyboard = () => {
    const field = props.focusedInput;
    const previousFieldValue = field.current.value || "";
    const newValue = previousFieldValue.slice(0, previousFieldValue.length - 1);
    field.current.value = newValue;
    props.updateNewQtyDisplayData(+newValue, props?.currentProduct);
  };

  return (
    <>
      <NumberKeyboard
        visible={props.isVirtualKeyboardVisible}
        showCloseButton={false}
        customKey={["."]}
        confirmText="OK"
        onInput={(value) => onInputVirtualKeyboard(value)}
        onConfirm={() => {
          props.onConfirm(props.focusedInput.current.value);
          props.focusedInput.current.value = "";
        }}
        onDelete={onDeleteVirtualKeyboard}
      />
    </>
  );
}
