import { App, Dropdown, Modal, Space, Tabs, message } from "antd";
import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import TableProducts from "../create/TableProducts";
import InfoTab from "../create/InfoTab";
import { useAuth } from "../../../../hook/useAuth";
import { SaveOutlined, DownOutlined, StopOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import {
  addRecord,
  updateRecord,
  clearObjectStore,
} from "../../../../db/dexie-db/functions";
import { getTableTotals } from "../../../../utils/getTableTotals";
import { getUserSetting } from "../../../../api/settings/settings";
import { DOCUMENT_OPEN_ACTIONS, TABS_TITLES } from "../../_CONSTANTS/constants";
import { TABLES } from "../../../../db/constants/tables";
import { generateDocBarcode } from "../../../../db/documents-funtions";

const Main = (props) => {
  const { user } = useAuth();

  const showSumByDiff = getUserSetting("showSumByDiff", user.settings);
  const navigate = useNavigate();

  const action = props?.action;
  const documentForEdit = props?.document;

  const [loadingSaveButton, setLoadingSaveButton] = useState(false);
  const [comment, setComment] = useState("");

  useEffect(() => {
    if (documentForEdit) {
      setComment(documentForEdit.comment);
    }
  }, [documentForEdit]);

  const handleMenuClick = async () => {
    Modal.confirm({
      title: "Не зберігати?",
      onCancel: () => {
        return;
      },
      onOk: async () => {
        const clearedCurrentOrder = await clearObjectStore(props.dbTable);
        if (!clearedCurrentOrder) {
          return message.error("Помилка очищення документа");
        }
        props?.setProducts([]);
        if (props.action === DOCUMENT_OPEN_ACTIONS.CREATE) {
          return;
        } else {
          localStorage.removeItem("editingRevisionId");
          navigate("/revisions");
        }
      },
      okText: "Ні",
    });
  };

  const items = [
    {
      label: (
        <Space>
          <StopOutlined />
          Скасувати
        </Space>
      ),
      key: "1",
      onClick: handleMenuClick,
    },
  ];

  const saveDocumentHadler = async () => {
    setLoadingSaveButton(true);
    if (props.products.length === 0) {
      setLoadingSaveButton(false);
      return message.error("Додайте товари");
    }
    switch (action) {
      case DOCUMENT_OPEN_ACTIONS.EDIT:
        if (!documentForEdit || !action) {
          setLoadingSaveButton(false);
          return message.error("Помилка взагалі не реальна");
        }

        const updatedDocument = {
          ...props.document,
          updated: dayjs(),
          exported: "false",
          products: props.products,
          id: props.document.id,
          sum: getTableTotals(props.products, showSumByDiff).total,
          comment,
        };
        const updated = await updateRecord(
          TABLES.REVISIONS,
          Number(updatedDocument.id),
          updatedDocument
        );
        if (!updated) {
          setLoadingSaveButton(false);
          return message.error("Помилка оновлення документу");
        }
        localStorage.removeItem("editingRevisionId");
        await processClearDbTable(props.dbTable);
        message.success("Оновлено!");
        setLoadingSaveButton(false);
        break;
      case DOCUMENT_OPEN_ACTIONS.CREATE:
        const newDocument = {
          date: dayjs().format("DD.MM.YYYY"),
          created: dayjs(),
          updated: dayjs(),
          exported: "false",
          products: props.products,
          sum: getTableTotals(props.products, showSumByDiff).total,
          comment,
          docBarcode: generateDocBarcode(),
        };
        const result = await addRecord(TABLES.REVISIONS, newDocument);
        if (!result) {
          setLoadingSaveButton(false);
          return message.error(result.message);
        }
        await processClearDbTable(props.dbTable);
        message.success("Збережено");
        setLoadingSaveButton(false);
        break;
      default:
        break;
    }

    return navigate("/revisions");
  };

  const processClearDbTable = async (dbTable) => {
    const cleared = await clearObjectStore(dbTable);
    if (!cleared) {
      return message.error("Помилка очищення поточного документа");
    }
  };

  const operations = (
    <>
      <Dropdown.Button
        loading={loadingSaveButton}
        size="middle"
        icon={<DownOutlined />}
        menu={{
          items,
        }}
        onClick={() => saveDocumentHadler()}
      >
        <SaveOutlined /> Зберегти
      </Dropdown.Button>
    </>
  );

  const InfoTabMemo = useMemo(() => {
    return <InfoTab comment={comment} setComment={setComment}></InfoTab>;
  }, [comment]);

  const OperationsSlot = {
    right: operations,
  };

  const deftabs = [
    {
      label: TABS_TITLES.INFO,
      key: "1",
      children: InfoTabMemo,
    },
    {
      label: TABS_TITLES.PRODUCTS,
      key: "2",
      children: (
        <TableProducts
          columns={props?.columns}
          loading={props.loading}
          products={props.products}
          settings={props.settings}
          afterChangeProductQty={props.afterChangeProductQty}
          removeProductFromDocument={props.removeProductFromDocument}
          dbTable={props.dbTable}
        ></TableProducts>
      ),
    },
  ];

  return (
    <>
      <App>
        <Tabs
          tabBarStyle={{ marginBottom: "5px" }}
          tabBarExtraContent={OperationsSlot}
          defaultActiveKey="2"
          size={"middle"}
          items={deftabs}
        />
      </App>
    </>
  );
};
export default Main;
