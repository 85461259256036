import {
  Button,
  Col,
  Drawer,
  InputNumber,
  message,
  Popconfirm,
  Row,
} from "antd";
import React, { useRef, useState } from "react";
import { CloseOutlined, DeleteOutlined } from "@ant-design/icons";
import { updateRecord } from "../../../db/dexie-db/functions";

export default function ModalEditProductQty(props) {
  const [qtyInputValue, setQtyInputValue] = useState();

  const inputLastProductQty = useRef();

  const decrementQty = () => {
    if (
      qtyInputValue === 0 ||
      qtyInputValue === "" ||
      qtyInputValue === 1 ||
      !qtyInputValue
    ) {
      setQtyInputValue(1);
    } else {
      setQtyInputValue(+qtyInputValue - 1);
    }
  };

  const incrementQty = () => {
    if (qtyInputValue === 0 || qtyInputValue === "" || !qtyInputValue) {
      setQtyInputValue(1);
    } else {
      setQtyInputValue(+qtyInputValue + 1);
    }
  };

  const saveButtonHandler = async () => {
    await processChangeProductQty(
      props.dbTable,
      props.productToChangeQty,
      qtyInputValue
    );
  };

  const plusButtonHandler = async () => {
    const enteredQty = qtyInputValue === "" ? 1 : +qtyInputValue;
    const newQty = !+props.productToChangeQty.qty
      ? 0 + enteredQty
      : +props.productToChangeQty.qty + enteredQty;
    await processChangeProductQty(
      props.dbTable,
      props.productToChangeQty,
      newQty
    );
  };

  const processChangeProductQty = async (dbTable, product, newQty) => {
    newQty = +newQty ? +newQty : 0;
    const newProduct = { ...product, qty: newQty };

    if (dbTable) {
      await changeProductQty(dbTable, newProduct);
    }
    props.afterChangeProductQty(newProduct);
    props.setIsModalChangeLastProductOpen(false);
  };

  const changeProductQty = async (dbTable, newProduct) => {
    const updated = await updateRecord(dbTable, newProduct.guid, newProduct);
    if (!updated) {
      message.error("Помилка оновлення кількості");
      return false;
    }
    return true;
  };

  const deleteProductHandler = async (product) => {
    await props.removeProductFromDocument(product);
    props.afterChangeProductQty(product);
    props.setIsModalChangeLastProductOpen(false);
  };

  const footer = (
    <Row style={{ textAlign: "center" }}>
      <Col span={12}>
        <Button
          size="large"
          style={{ width: "90%" }}
          key="submit"
          type="primary"
          onClick={plusButtonHandler}
        >
          +
        </Button>
      </Col>

      <Col span={12}>
        <Button
          size="large"
          style={{ width: "90%" }}
          key="save"
          type="primary"
          onClick={saveButtonHandler}
        >
          Зберегти
        </Button>
      </Col>
    </Row>
  );

  return (
    <>
      <Drawer
        afterOpenChange={(open) => {
          if (!open) return setQtyInputValue;

          requestAnimationFrame(() => {
            inputLastProductQty.current.focus();
          });
        }}
        title={
          <div style={{ width: "95%" }}>{props.productToChangeQty?.title}</div>
        }
        styles={{
          body: { padding: "5px 15px", overflow: "hidden", minHeight: "10vh" },
          header: { padding: "15px" },
          footer: { padding: "10px" },
        }}
        placement="top"
        height={window.screen.height * 0.35}
        open={props.isModalChangeLastProductOpen}
        maskClosable={false}
        onClose={() => {
          props.setIsModalChangeLastProductOpen(false);
        }}
        footer={footer}
        closeIcon={null}
        extra={
          <CloseOutlined
            onClick={async () => {
              props.setIsModalChangeLastProductOpen(false);
            }}
          ></CloseOutlined>
        }
      >
        {props.productToChangeQty?.stockQty && props?.settings?.showRealCount
          ? `Облікова кількість: ${props.productToChangeQty.stockQty}`
          : ""}
        <br></br>
        {`Ціна: ${props.productToChangeQty?.price.toFixed(2)} грн`}
        <br></br>
        <br></br>
        <Row
          style={{
            margin: "0px 15px",
          }}
        >
          <Col span={6} style={{ textAlign: "center" }}>
            <Popconfirm
              title={"Видалити позицію?"}
              onCancel={() => {
                return;
              }}
              onConfirm={() => deleteProductHandler(props.productToChangeQty)}
            >
              <Button size="large">
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Col>
          <Col span={18} style={{ textAlign: "center" }}>
            <InputNumber
              id="modal-edit-product-qty-input-qty"
              size="large"
              ref={inputLastProductQty}
              autoFocus={true}
              {...(props.productToChangeQty?.isWeight
                ? null
                : { precision: 0 })}
              style={{ width: "70%" }}
              controls={false}
              type={"tel"}
              placeholder="К-ть"
              parser={(value) => {
                if (props.productToChangeQty?.isWeight) {
                  const valueFloat = parseFloat(value.replace(",", "."));
                  return valueFloat;
                }
                const valueInt = parseInt(value.replace(",", "."));
                return valueInt;
              }}
              onChange={(e) => {
                setQtyInputValue(e);
              }}
              value={qtyInputValue}
              addonBefore={
                <span style={{ padding: "10px" }} onClick={decrementQty}>
                  -
                </span>
              }
              addonAfter={
                <span style={{ padding: "10px" }} onClick={incrementQty}>
                  +
                </span>
              }
            />
          </Col>
        </Row>
      </Drawer>
    </>
  );
}
