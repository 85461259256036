import React, { useEffect, useState } from "react";
import { Outlet } from "react-router";
import { isUpdateNeeded } from "../api/Import/isUpdateNeeded";
import ImportModal from "../components/import/ImportModal";
import UpdateAvailableModal from "../components/import/UpdateAvailableModal";
import { useAuth } from "../hook/useAuth";
import { ConfigProvider, Row, theme } from "antd";
import { useTheme } from "../hook/useTheme";
import { getUserSetting } from "../api/settings/settings";
const { darkAlgorithm } = theme;

export default function Layout() {
  const { user } = useAuth();
  const { useDarkMode, toggleThemeMode } = useTheme();

  const userUseDarkMode = getUserSetting("darkMode", user.settings, "global");
  useEffect(() => {
    toggleThemeMode(userUseDarkMode);
  }, []);

  const [isOpenUpdateDataModal, setIsOpenUpdateDataModal] = useState(false);
  const [isOpenUpdateAvailableModal, setIsOpenUpdateAvailableModal] =
    useState(false);

  const darkMode = {
    algorithm: darkAlgorithm,
    token: {
      colorPrimary: "#6b6b6b",
    },
  };

  const lightMode = {
    token: {
      colorPrimary: "#202124",
    },
  };

  useEffect(() => {
    if (user.username === "demo") return;
    getData();
  }, []);

  useEffect(() => {
    setTheme();
  }, [useDarkMode]);

  const setTheme = () => {
    if (useDarkMode) {
      const root = document.getElementById("root");
      root.style.backgroundColor = "black";
      document.documentElement.style.backgroundColor = "black";
      document.documentElement.setAttribute("data-bs-theme", "dark");
    } else {
      const root = document.getElementById("root");
      root.style.backgroundColor = "white";
      document.documentElement.style.backgroundColor = "white";
      document.documentElement.setAttribute("data-bs-theme", "light");
    }
  };

  const getData = async () => {
    const updated = localStorage.getItem("updated");
    const updatedNeeded = await isUpdateNeeded(
      updated,
      user.exchangeType,
      user.username
    );

    if (!updatedNeeded) return;

    if (!updated || updatedNeeded) {
      return updateData();
    }
  };
  const updateData = async () => {
    setIsOpenUpdateAvailableModal(true);
  };

  return (
    <>
      <ConfigProvider theme={useDarkMode ? darkMode : lightMode}>
        <div style={{ padding: "0px 5px" }}>
          <Outlet></Outlet>
          <UpdateAvailableModal
            open={isOpenUpdateAvailableModal}
            setIsOpenUpdateAvailableModal={setIsOpenUpdateAvailableModal}
            setIsOpenUpdateDataModal={setIsOpenUpdateDataModal}
          ></UpdateAvailableModal>
          <ImportModal
            open={isOpenUpdateDataModal}
            setIsOpenUpdateDataModal={setIsOpenUpdateDataModal}
          ></ImportModal>
          {user.username === "demo" ? (
            <Row justify={"center"}>
              <p
                style={{
                  position: "fixed",
                  textAlign: "center",
                  bottom: "10px",
                  color: "red",
                }}
              >
                Демо-версія! <br></br>Використовується тільки для вивчення
                можливостей додатку
              </p>
            </Row>
          ) : (
            ""
          )}
        </div>
        <div style={{ paddingBottom: "100px" }}></div>
      </ConfigProvider>
    </>
  );
}
