import React from "react";
import ReactDOM from "react-dom/client";
import "./components/UI/ui.css";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import ukUA from "antd/lib/locale/uk_UA";
import { CookiesProvider } from "react-cookie";
import { swWaapp, swWaappCheckStorage } from "./serviceWorkers/swWaapp";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ConfigProvider
        locale={ukUA}
        theme={{
          token: {
            colorPrimary: "#202124",
          },
        }}
      >
        <CookiesProvider defaultSetCookies={{ path: "/" }}>
          <App />
        </CookiesProvider>
      </ConfigProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// if (navigator.serviceWorker) {
// window.addEventListener("load", () => {
// swWaapp();
// swWaappCheckStorage();
// });
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
