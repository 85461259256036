import { Button } from "antd";
import React, { useState } from "react";
import Menu from "../../../menu/Menu";
import ImportModal from "../../../import/ImportModal";

export default function MenuComponent() {
  const [isOpenUpdateDataModal, setIsOpenUpdateDataModal] = useState(false);

  const dataTab = (
    <Button
      onClick={() => {
        setIsOpenUpdateDataModal(true);
      }}
    >
      Оновити дані
    </Button>
  );
  const settingsTab = <></>;
  return (
    <>
      <Menu dataTab={dataTab} settingsTab={settingsTab}></Menu>
      <ImportModal
        open={isOpenUpdateDataModal}
        setIsOpenUpdateDataModal={setIsOpenUpdateDataModal}
      ></ImportModal>
    </>
  );
}
