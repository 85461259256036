import { registerGAEvent } from "../../../analytics/React-ga";
import { sendTGMessage, statistics } from "../../../statistics/statistics";
import { getServer } from "../../../utils/Environment";

const server = getServer();

const url = server + "/server/server.php";

export const exportDocument = async (docObj, docType, user) => {
  if (!navigator.onLine) {
    return { status: "failed", message: "Немає інтернету!" };
  }

  let fileName = docObj.dateTime.split(" ").join("-");
  fileName = fileName.split(".").join("-");
  fileName = fileName.split(":").join("-");

  const result = await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      docType, //це об'єкт: docType.title - Перевірка цін, docType.value - check-price
      username: user.username,
      doc: docObj,
      fileName,
      action: "exportDocument",
    }),
  });

  try {
    const res = await result.json();

    registerGAEvent({
      category: user.company,
      action: user.username + " - " + docType.title,
      label: "Експорт",
    });
    statistics(user, docType.title);
    return res;
  } catch (error) {
    sendTGMessage(
      "Користувач: " + user.username + ", Помилка експорту: " + error
    );
    registerGAEvent({
      category: user.company,
      action: user.username + " - " + docType.title,
      label: "Помилка експорту",
    });
    return { status: "failed", message: error };
  }
};
