export const updateNewQtyDisplayData = (value, product) => {
  if (!product) return;
  if (!value || value.toString().length > 6 || !Number(value)) {
    value = product?.isWeight ? 0.01 : 1;
  }

  // const enteredQty = await getEnteredProductQtyFromDb(props.dbTable, product);
  const enteredQty = product.qty ? product.qty : 0;
  // const enteredQty = await getEnteredProductQtyFromDb(props.dbTable, product);
  // const enteredQty = getEnteredProductQty(product, products);
  const newQty = (enteredQty + value).toFixed(product?.isWeight ? 3 : 0);

  const textContent = Number(enteredQty) + " \u2192 " + newQty;
  setTimeout(() => {
    const enteredQty = document.getElementById("enteredQtyData");
    if (enteredQty) {
      enteredQty.textContent = "";
      enteredQty.textContent = textContent;
    }
  }, 100);
};
