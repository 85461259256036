import { Button, Col, Modal, Popconfirm, Row, message } from "antd";
import React, { useEffect, useState } from "react";
import TableCharacteristics from "./TableCharacteristics";
import { getCharacteristicTotals } from "../../../../utils/getTableTotals";
import { getExistingProductFromDocument } from "../modal-enter-product/enter-products-functions";
import {
  CHARACTERISTICS_ACTION,
  DOCUMENTS_EDITING_TITLES,
  DOCUMENTS_TITLES,
} from "../../_CONSTANTS/constants";
import { DeleteOutlined } from "@ant-design/icons";
import { getProductCharacteristicsToTable } from "./characteristics-functions";
import { useAuth } from "../../../../hook/useAuth";
import { getUserSetting } from "../../../../api/settings/settings";
import { updateRecord } from "../../../../db/dexie-db/functions";

export default function ModalEnterCharacteristics(props) {
  const { user } = useAuth();
  const showSumByDiff = getUserSetting(
    "showSumByDiff",
    user.settings,
    "revizia"
  );
  const product = props.product;

  const [characteristics, setCharacteristics] = useState([]);

  useEffect(() => {
    if (product) getData();
  }, [product]);

  const getData = async () => {
    const characteristicsToTable = await getProductCharacteristicsToTable(
      product,
      "",
      props.dbTable,
      props.products
    );
    product.characteristics = characteristicsToTable;
    if (characteristicsToTable) setCharacteristics(characteristicsToTable);
  };

  const processChangeProductQty = async (dbTable, product, newQty) => {
    const newProduct = { ...product, qty: newQty };

    if (dbTable) {
      await changeProductQty(dbTable, newProduct);
    }
    if (props?.afterChangeProductQty) props.afterChangeProductQty(newProduct);
  };

  const changeProductQty = async (dbTable, newProduct) => {
    const updated = await updateRecord(dbTable, newProduct.guid, newProduct);
    if (!updated) {
      message.error("Помилка оновлення кількості");
      return false;
    }
    return true;
  };

  const saveCharacteristics = async () => {
    let validCharacteristics = false;
    characteristics.forEach((element) => {
      if (element.qty) return (validCharacteristics = true);
    });
    if (!validCharacteristics) return message.error("Введіть кількість!");

    const totalProductQty = getCharacteristicTotals(characteristics);
    product.characteristics = characteristics;
    product.qty = totalProductQty.quantity;
    const existingProduct = await getExistingProductFromDocument({
      product,
      products: props.products,
      dbTable: props.dbTable,
    });

    if (props.action === CHARACTERISTICS_ACTION.ADD && existingProduct) {
      product.qty = existingProduct.qty + totalProductQty.quantity;
    }

    if (props?.action === CHARACTERISTICS_ACTION.EDIT) {
      await processChangeProductQty(props.dbTable, product, product.qty);
    } else {
      await props.addProductToCurrentDocument(product, props.products);
    }
    props.setIsModalEnterCharacteristicsOpen(false);
    if (props?.afterChangeProductQty)
      props.afterChangeProductQty(product, props.products);
  };

  const deleteProductHandler = async (product) => {
    await props.removeProductFromDocument(product);
    props.setIsModalEnterCharacteristicsOpen(false);
  };

  const footer = (
    <Row>
      {props?.action === CHARACTERISTICS_ACTION.EDIT ? (
        <Col span={2}>
          <Popconfirm
            title={"Видалити позицію?"}
            onCancel={() => {
              return;
            }}
            onConfirm={() => deleteProductHandler(product)}
          >
            <Button>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Col>
      ) : (
        <Col span={2}></Col>
      )}

      <Col span={10}></Col>
      <Col span={12}>
        <Button
          // size="large"
          style={{ width: "90%" }}
          key="submit"
          type="primary"
          onClick={saveCharacteristics}
        >
          Зберегти
        </Button>
      </Col>
    </Row>
  );

  return (
    <>
      <Modal
        title={product?.title}
        style={{ top: "10px", padding: "10px 15px !important" }}
        className="modalEnterCharacteristics"
        open={props.isModalEnterCharacteristicsOpen}
        onCancel={() => {
          props.setIsModalEnterCharacteristicsOpen(false);
          if (props.setIsModalProductOpen) props.setIsModalProductOpen(true);
          if (props?.afterClose) props?.afterClose();
        }}
        footer={footer}
      >
        {product?.info ? (
          <i style={{ padding: 5, textDecoration: "underline" }}>
            {product.info}
          </i>
        ) : (
          ""
        )}
        <TableCharacteristics
          characteristics={characteristics}
          setCharacteristics={setCharacteristics}
          showTotalCharacteristicsByDiff={
            props?.serviceTitle === DOCUMENTS_TITLES.REVISION ||
            props?.serviceTitle === DOCUMENTS_EDITING_TITLES.EDITING_REVISION ||
            !showSumByDiff
              ? true
              : false
          }
        ></TableCharacteristics>
      </Modal>
    </>
  );
}
